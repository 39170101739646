<template>
  <div class="form-container">
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(doNext)">
        <fieldset>
          <legend>{{ $t("signUp.title.user") }}</legend>
          <div>
            <!-- 개인정보 제3자 제공 안내 -->
            <h6 class="h6-16">{{ $t('content.signup.popup.IntegratedUser.txt01') }}</h6>

            <ul>
              <!-- 이메일 -->
              <li class="form-row">
                <BaseInput
                  v-model="userInfo.email"
                  id="snsName"
                  disabled
                  :invalid="isDuplicateEmail"
                />
                <span
                  v-if="isDuplicateEmail"
                  class="input-error-desc"
                  v-html="$t('content.signup.popup.DuplicatedEmail.txt02',
                  { snsName: userInfo.snsName, snsRole: snsRole })"
                ></span>
                <span
                  v-else
                  class="input-desc"
                >{{ $t('content.signup.OauthUserInfo.txt02') }}</span>
              </li>

              <!-- 사용자 닉네임 -->
              <li class="form-row">
                <ValidationProvider
                  :name="$t('content.signup.OauthUserInfo.txt03')"
                  :rules="{ required: true, regex: /^[ㄱ-ㅎ가-힣a-zA-Z0-9 ]+$/ }"
                  v-slot="{ errors, failed }">
                  <BaseInput
                    v-model="name"
                    id="userName"
                    :placeholder="$t('content.signup.OauthUserInfo.txt03')"
                    :invalid="failed"
                  />
                  <span
                    v-if="invalid"
                    class="input-error-desc">{{$t('content.signup.OauthUserInfo.txt03')}} is not valid
                  </span>
                </ValidationProvider>
              </li>
            </ul>
          </div>

        <!-- 버튼 -->
        <div class="btn-wrap">
          <BaseButton
            type="line"
            @click="cancel"
          >{{$t('content.common.button.btn05')}}</BaseButton>
          <BaseButton
            nativeType="submit"
            :disabled="invalid || isDuplicateEmail"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>

        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import routeTypes from '@/router/routeTypes';

export default {
  name: 'SignUpStep2',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseInput,
    BaseButton,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    isDuplicateEmail: {
      type: Boolean,
    },
    loginRole: {
      type: String,
    },
  },

  data() {
    return {
      name: this.userInfo.name,
    };
  },
  computed: {
    snsRole() {
      const roleArr = [];
      roleArr.MB201 = this.$t('commonCode.MB201');
      roleArr.MB202 = this.$t('commonCode.MB202');
      roleArr.MB203 = this.$t('commonCode.MB203');
      roleArr.MB204 = this.$t('commonCode.MB204');
      roleArr.MB205 = this.$t('commonCode.MB205');
      return roleArr[this.loginRole];
    },
  },
  mounted() {
    if (!this.userInfo) {
      this.cancel();
    }
  },

  methods: {
    cancel() {
      this.$router.replace({
        name: routeTypes.ROUTE_NAME_SIGNUP,
      });
    },
    doNext() {
      if (this.isDuplicateEmail) {
        this.showAlert(this.$t('content.signup.UserInfo.txt16'));
      } else {
        const user = {
          ...this.userInfo,
          name: this.name.trim(),
        };
        const next = {
          name: 'SignUpStep3',
          params: {
            userInfo: user,
          },
        };
        this.$router.replace(next);
      }
    },
  },
};
</script>
